import React, { FC } from 'react';
import { Link } from 'gatsby';
import './Chip.scss';

interface IChip {
  text: string;
  linkTo: string;
}

const Chip: FC<IChip> = ({
  text,
  linkTo,
}: IChip) => {
  if (linkTo) {
    return (
      <div className="chip">
        <Link to={linkTo}>
          <span className="chip__text">{text}</span>
        </Link>
      </div>
    );
  }

  return (
    <div className="chip">
      <span className="chip__text">{text}</span>
    </div>
  );
};

export default Chip;
