import React, { FC } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface Props {
  language: string;
  children: any;
}

const PostCode: FC<Props> = ({
  language,
  children,
}: Props) => {
  return (
    <SyntaxHighlighter
      style={vscDarkPlus}
      language={language}
      showLineNumbers
      lineNumberStyle={{ textAlign: 'left', padding: '0 4px' }}
    >
      {children}
    </SyntaxHighlighter>
  );
};

export default PostCode;
