import React, { FC } from 'react';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import { ContactHelper } from '../../utils';
import './PostFooter.scss';

const PostFooter: FC = () => {
  const { author } = useSiteMetadata();

  return (
    <div className="postFooter">
      <blockquote>
        Please feel free to drop me a mail if you have any questions
        or interests to do some collaboration works @
        <a href={ContactHelper.getContactHref('email', author.contacts.email)}>
          {author.contacts.email}
        </a>
      </blockquote>
    </div>
  );
};

export default PostFooter;
