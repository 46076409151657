import parse, { domToReact } from 'html-react-parser';
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Chip from '../components/Chip';
import NavHeader from '../components/NavHeader';
import PostCode from '../components/PostCode';
import PostFooter from '../components/PostFooter';
import { Layout } from '../components';
import { NavHelper } from '../utils';
import { GetPostBySlugQuery } from '../../graphql-types';
import './post.scss';

const getExcerptContent = (excerpt: string): string => {
  return excerpt
    ?.split('<p>')[1]
    ?.split('</p>')[0];
};

const getLanguage = (node: any) => {
  if (node.attribs.class != null) {
    return node.attribs.class.split('language-')[1];
  }

  return null;
};

const getCode = (node: any) => {
  if (node.children.length > 0 && node.children[0].name === 'code') {
    return node.children[0].children;
  }
  return node.children;
};

const replaceCode = (node: any): JSX.Element | null => {
  // Requires to check using pre because some `code` block is inline block
  if (node.name === 'pre') {
    if (node.children.length > 0) {
      return (
        <PostCode
          language={getLanguage(node.children[0])}
        >
          {domToReact(getCode(node.children[0]))}
        </PostCode>
      );
    }
    return null;
  }

  return null;
};

type PostTemplateProps = {
  data: GetPostBySlugQuery;
}

const PostTemplate: FC<PostTemplateProps> = ({
  data,
}) => {
  const { html, frontmatter: f } = data.allMarkdownRemark.nodes[0];

  return (
    <Layout
      title={f?.title || ''}
      description={getExcerptContent(f?.excerpt || '')}
      socialImage={f?.thumbnail?.publicURL || ''}
      isIndex={false}
      tags={(f?.tags || []) as string[]}
    >
      <div className="post">
        <NavHeader />
        <h1 className="post__title">{f?.title || ''}</h1>
        <div className="post__content">
          {parse(html || '', { replace: replaceCode })}
        </div>
        <hr />
        <h6 className="post__tagSection__title">Tags:</h6>
        <div className="post__tagSection">
          {(f?.tags || []).sort().map((tag) => {
            return (
              <span key={tag} className="post__tagSection__tag">
                <Chip
                  text={tag || ''}
                  linkTo={NavHelper.getSlugUrl('category', `${tag}`)}
                />
              </span>
            );
          })}
        </div>
        <PostFooter />
      </div>
    </Layout>
  );
};

export const query = graphql`
query GetPostBySlug($slug: String) {
  allMarkdownRemark(filter: {frontmatter: {slug: {eq: $slug}}}) {
    nodes {
      frontmatter {
        title
        excerpt
        tags
        thumbnail {
          publicURL
        }
      }
      html
    }
  }
}
`;

export default PostTemplate;
