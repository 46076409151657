import React, { FC } from 'react';
import { Link } from 'gatsby';
import Icon from '../Icon';
import { IconHelper } from '../../utils';
import './NavHeader.scss';

const NavHeader: FC = () => {
  return (
    <div className="navHeader">
      <Link to="/" className="navHeader__home">
        <Icon
          className="navHeader__icon--leftArrow"
          name=""
          icon={IconHelper.getIcon('leftArrow')}
        />
        <Icon
          className="navHeader__icon--home"
          name="Home"
          icon={IconHelper.getIcon('home')}
        />
        &nbsp;
        <span>
          Home
        </span>
      </Link>
    </div>
  );
};

export default NavHeader;
